import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import RuledBreak from "../components/RuledBreak"

const IndexPage = ({ data }) => {
  const header = data.header.edges[0].node.childImageSharp.fluid
  const headerImgWhite = data.headerImgWhite.edges[0].node.childImageSharp.fluid
  const headerImgBlack = data.headerImgBlack.edges[0].node.childImageSharp.fluid
  const photoshopLeg = data.photoshopLeg.edges[0].node.childImageSharp.fluid
  const photoshopChest = data.photoshopChest.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={header}
      alt="Welcome Home Header"
      imgDark={headerImgWhite}
      imgLight={headerImgBlack}
      height={600}
      heightMobile={300}
    >
      <SEO
        title="Welcome"
        keywords={[
          "Beyond The Apprenticeship",
          "Matt Pehrson",
          "Tattoos",
          "Owner",
        ]}
      />
      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>Welcome</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto py-3">
          <h3 className="text-center">
            If you’re a tattoo artist, and you’re ready to invest in yourself
            and your success, you have come to the right place!
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto py-3">
          <h3 className="text-center">[? VIDEO HERE ?]</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5 mr-auto ml-auto py-3 d-flex align-items-center text-center">
          <h3>
            Have you been searching for continuing education which focuses on
            the business side of tattooing? Are you ready to make more money?
          </h3>
        </div>
        <div className="col-md-4 mr-auto ml-auto py-3">
          <Img
            fluid={photoshopChest}
            alt="Photoshop Action"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>Beyond The Apprenticeship</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mr-auto ml-auto py-3">
          <Img
            fluid={photoshopLeg}
            alt="Photoshop Action"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
        <div className="col-md-5 mr-auto ml-auto py-3 d-flex align-items-center text-center">
          <h3>
            At Beyond the Apprenticeship, we focus on training in the areas that
            will bring you more financial success and allow you the freedom to
            spend time on the projects that inspire you.
          </h3>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    header: allFile(filter: { name: { eq: "headerHome_1300x687" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    headerImgWhite: allFile(
      filter: { name: { eq: "bta_logo_w_shadow_600x186" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    headerImgBlack: allFile(
      filter: { name: { eq: "bta_logo_b_shadow_600x186" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    photoshopLeg: allFile(filter: { name: { eq: "photoshop_leg_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    photoshopChest: allFile(
      filter: { name: { eq: "photoshop_chest_600x600" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default IndexPage
